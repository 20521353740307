/**
 * Created by dansen on 7/14/16.
 */
export const ANONYMOUS_CLIENT_ID = '2_47b2d3742f784696f05ac14b4c977b85693bda2d';
export const ANONYMOUS_CLIENT_SECRET = '3bc6f059165f16cf17f86b160d899515f158df48';
export const USER_CLIENT_ID = '1_7aa06f2c0e7bf0b7503bfab3cdea8b8d472ec5be';
export const USER_CLIENT_SECRET = '2eab171511d006841615c0f1fc956df47641ba79';

// GLOSUS Google Cloud Platform Servers
// const GLOSUS_API_VM0 = 'https://apicenter.glosus.com/'; // Production
//  const GLOSUS_TEST_VM0 = 'https://testapicenter.glosus.com/'; // Test
const GLOSUS_TEST_VM0 = 'https://testapi.glosus.eu/'; // Test
//const GLOSUS_DEMO_VM0 = 'https://demoapicenter.glosus.com/'; // Test
// const GLOSUS_LOCAL_VM0 = 'http://localhost:81/'; // Local

// Local Enviroment
// export const API_BASE_URL = 'http://localhost:3000/api/'; //dev
// export const DOWNLOAD_BASE_URL = 'http://api.glosus.dev/'; //dev

// Testing Enviroment
export const API_BASE_URL = GLOSUS_TEST_VM0 + 'api/';
export const DOWNLOAD_BASE_URL = GLOSUS_TEST_VM0 + 'api/web/';

// Production Enviroment
// export const API_BASE_URL = GLOSUS_API_VM0 + 'api/';
// export const DOWNLOAD_BASE_URL = GLOSUS_API_VM0;

// Dev Enviroment
// export const API_BASE_URL = GLOSUS_LOCAL_VM0 + 'api/';
// export const DOWNLOAD_BASE_URL = GLOSUS_LOCAL_VM0;

export const COMPANY_API_BASE_URL = API_BASE_URL + 'web/companies';
export const EXTERNAL_COMPANY_API_BASE_URL = API_BASE_URL + 'web/external/companies';
export const BILL_ADDRESSES_API_BASE_URL = API_BASE_URL + 'web/bill_addresses';
export const BILL_CONTACTS_API_BASE_URL = API_BASE_URL + 'web/bill_contacts';
export const BILL_HISTORIES_API_BASE_URL = API_BASE_URL + 'web/bill_histories';
export const EMPLOYEES_API_BASE_URL = API_BASE_URL + 'web/employees';
export const SUGGESTIONS_API_BASE_URL = API_BASE_URL + 'web/suggestions';
export const MANAGERS_API_BASE_URL = API_BASE_URL + 'web/managers';
export const SDGS_API_BASE_URL = API_BASE_URL + 'web/sdgs';
export const CSR_TEAM_MEMBERS_API_BASE_URL = API_BASE_URL + 'web/csr-team-members';
export const BRANDS_API_BASE_URL = API_BASE_URL + 'web/brands';
export const AWARDS_API_BASE_URL = API_BASE_URL + 'web/awards';
export const OWNERSHIPS_API_BASE_URL = API_BASE_URL + 'web/ownerships';
export const SUBSIDIARIES_API_BASE_URL = API_BASE_URL + 'web/subsidiaries';
export const BRANCHES_API_BASE_URL = API_BASE_URL + 'web/branches';
export const POSTS_API_BASE_URL = API_BASE_URL + 'web/posts';
export const POST_IMAGES_API_BASE_URL = API_BASE_URL + 'web/post_images';
export const POST_IDEAS_API_BASE_URL = API_BASE_URL + 'web/post_ideas';
export const POST_SETTING_API_BASE_URL = API_BASE_URL + 'web/post_setting';
export const BLOG_API_BASE_URL = API_BASE_URL + 'web/blog';
export const BADGE_DATA_API_BASE_URL = API_BASE_URL + 'web/badge/data';
export const BADGES_API_BASE_URL = API_BASE_URL + 'web/badges';
export const GROUPS_API_BASE_URL = API_BASE_URL + 'web/groups';
export const MEMBERS_API_BASE_URL = API_BASE_URL + 'web/members';
export const CATEGORIES_API_BASE_URL = API_BASE_URL + 'web/categories';
export const CATEGORY_API_BASE_URL = API_BASE_URL + 'web/category';
export const CRITERION_API_BASE_URL = API_BASE_URL + 'web/criterion';
export const FIELDS_API_BASE_URL = API_BASE_URL + 'web/fields';
export const DATA_TOOL_API_BASE_URL = API_BASE_URL + 'web/data-aggregation';
export const USER_API_BASE_URL = API_BASE_URL + 'web/user';
export const NEWS_API_BASE_URL = API_BASE_URL + 'web/newses';
export const ANALYTICS_API_BASE_URL = API_BASE_URL + 'web/analytics';
export const BUG_API_BASE_URL = API_BASE_URL + 'web/bugs';
export const COMPANY_MANUALS_API_BASE_URL = API_BASE_URL + 'web/company_manuals';
export const TOKEN_API_URL = API_BASE_URL + 'oauth/v2/token';
export const PREFERENCES_API_BASE_URL = API_BASE_URL + 'web/preferences';
export const TAGS_API_BASE_URL = API_BASE_URL + 'web/preferences-tag';

export const MCD_API_BASE_URL = API_BASE_URL + 'web/mcd';
export const MCD_RESTAURANT_API_BASE_URL = MCD_API_BASE_URL + '/restaurants';
export const MCD_WASTE_SURVEYS_API_BASE_URL = MCD_API_BASE_URL + '/waste/surveys';
export const MCD_WASTE_COMPANIES_API_BASE_URL = MCD_API_BASE_URL + '/waste/companies';

